import React from "react";
import '../styles/section1.css';

import pandas from '../img/graphics/TWIN_Sublogo.png'
import NavbarMobile from './NavbarMobile';
const Section1 = ({ children }) => {
  return (
    <div className="chapter1Background">
      <div className="chapter1Container">
        <NavbarMobile></NavbarMobile>
        <div className="main">
          <div className="chapter1Logo">
              <img src={pandas} alt="Pandas" width="100%"/>
          </div>

          <div className="">
            <div className="chapter1Headline">
              Welcome to TWIN Finance
            </div>
            <div className = "claimText">            
              Access any Real World Asset on Berachain
            </div>
            <div className="chapter1ButtonBox">
              <a href="https://app.twinfinance.io" rel="noopener">
                <div className="chapter1ButtonGreen">
                    Try it now                  
                </div>
              </a>
              &nbsp;&nbsp;&nbsp;
              
              <a href="https://discord.gg/9ZwbkdjmtJ" rel="noopener">
                <div className="chapter1ButtonGreenUnfilled">
                    Join our Discord                 
                </div>
              </a>
              &nbsp;&nbsp;&nbsp;
              <a href="https://docs.twinfinance.io" rel="noopener">
                <div className="chapter1ButtonGreen">
                    Read our Docs                  
                </div>
              </a>
            </div>
          </div>
        </div>  
      </div>

    </div>

      
  
  );
};

export default Section1;